module.exports = {
  defaultLocale: 'de',
  locales: {
    en: {
      path: `en`,
      locale: `en-US`,
    },
    de: {
      path: `de`,
      locale: `de-DE`,
    },
  },
};
