import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Footer from '@components/Footer';
import Header from '@components/Header';
import { AlternatePages } from '@components/SEO';
import { defaultLocale } from '@config/i18n';
import { isBrowser } from '@helpers/browser';
import useLocalStorage from '@hooks/useLocalStorage';
import loadable from '@loadable/component';

import * as css from './index.module.css';

const CookieBanner = loadable(() => import('../CookieBanner'));

export interface LayoutProps {
  children: ReactNode;
  data: any;
  location: any;
  alternatePages: AlternatePages[];
}

export const PureLayout: FC<LayoutProps> = ({
  children,
  alternatePages,
  location,
  data,
}) => {
  const { locale, formatMessage } = useIntl();
  const [isSticky, setSticky] = useState(false);
  const [hasClosed, setHasClosed] = useLocalStorage('cookieAccepted', false);

  const footerNavigationItems =
    data.footerNavigation.nodes.filter(
      (item: any) => item.node_locale === locale
    )?.[0]?.navigationElements || [];

  const mainNavigationItems =
    data.mainNavigation.nodes.filter(
      (item: any) => item.node_locale === locale
    )?.[0]?.navigationElements || [];

  const stickyRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const offset = stickyRef?.current?.offsetTop || 0;
    return window.pageYOffset > offset ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <a href="#skip-nav" className={css.layout__skipContent}>
        {formatMessage({ id: 'general.skipContent' })}
      </a>

      <div
        ref={stickyRef}
        className={classNames(css.layout__header, {
          [css.layout__headerSticky]: isSticky,
        })}
      >
        <Header
          key={isBrowser ? location?.pathname : 'header'}
          isSticky={isSticky}
          navigationItems={mainNavigationItems}
        />
      </div>

      <main className={css.layout__wrapper} id="skip-nav">
        {children}
      </main>

      <Footer
        alternatePages={alternatePages}
        navigationItems={footerNavigationItems.map((item: any) => ({
          id: item.parentPage.contentful_id,
          to:
            locale === defaultLocale
              ? `/${item.parentPage.slug}/`
              : `/${locale}/${item.parentPage.slug}/`,
          children: item.parentPage.name,
        }))}
      />

      {!hasClosed && <CookieBanner handleOnClick={() => setHasClosed(true)} />}
    </>
  );
};

export const Layout: FC<LayoutProps> = (props) => {
  const data = useStaticQuery(graphql`
    query MainNavigationQuery {
      mainNavigation: allContentfulNavigation(
        filter: { name: { eq: "Main Navigation" } }
      ) {
        nodes {
          ...Navigation
        }
      }

      footerNavigation: allContentfulNavigation(
        filter: { name: { eq: "Footer Navigation" } }
      ) {
        nodes {
          ...Navigation
        }
      }
    }
  `);
  return <PureLayout {...props} data={data} />;
};

export default Layout;
