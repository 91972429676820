import classNames from 'classnames';
import React, { FC, useState } from 'react';

import Link from '@blocks/Link';
import { defaultLocale } from '@config/i18n';
import useLockedBody from '@hooks/useLockedBody';

import Burger from './burger.inline.svg';
import Close from './close.inline.svg';
import * as css from './index.module.css';
import { useIntl } from 'react-intl';

export interface NavigationProps {
  navigationItems: any;
}

const Navigation: FC<NavigationProps> = ({ navigationItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [locked, setLocked] = useLockedBody();
  const { locale } = useIntl();

  const items = navigationItems.map((item: any) => {
    const parentPage = item?.parentPage;

    return {
      parentPage: {
        children: parentPage.name,
        to:
          locale === defaultLocale
            ? `/${parentPage.slug}/`
            : `/${locale}/${parentPage.slug}/`,
        id: parentPage.contentful_id,
      },
    };
  });

  const openNavigation = () => {
    setIsOpen(!isOpen);
    setLocked(!locked);
  };

  const navigationClassNames = classNames(css.navigation__nav, {
    [css.navigation__navActive]: isOpen,
  });

  return (
    <div className={css.navigation}>
      <button
        className={css.navigation__action}
        onClick={openNavigation}
        aria-label="Open navigation"
        aria-controls="menu"
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <Close
            aria-hidden="true"
            width="20px"
            height="20px"
            className={css.navigation__closeIcon}
          />
        ) : (
          <Burger
            aria-hidden="true"
            width="20px"
            height="20px"
            className={css.navigation__closeIcon}
          />
        )}
      </button>
      <nav className={navigationClassNames}>
        <ul className={css.navigation__list} id="menu">
          {items.map((item: any) => (
            <li key={item.parentPage.id} className={css.navigation__listItem}>
              <Link
                to={item.parentPage.to}
                linkClassName={css.navigation__listItemLink}
                activeClassName={css.navigation__listItemLinkActive}
                partiallyActive={true}
              >
                {item.parentPage.children}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
