// extracted by mini-css-extract-plugin
export var footer = "index-module--footer--decd3";
export var footer__additionalInformation = "index-module--footer__additional-information--4d35c";
export var footer__address = "index-module--footer__address--d8693";
export var footer__copyright = "index-module--footer__copyright--d56cd";
export var footer__information = "index-module--footer__information--9a4a8";
export var footer__link = "index-module--footer__link--bc3b9";
export var footer__logo = "index-module--footer__logo--c6066";
export var footer__nav = "index-module--footer__nav--dbaab";
export var footer__navList = "index-module--footer__nav-list--a9f01";
export var footer__navListItem = "index-module--footer__nav-list-item--ff189";
export var footer__social = "index-module--footer__social--5cdfe";
export var footer__socialImage = "index-module--footer__social-image--9e10f";
export var footer__socialItem = "index-module--footer__social-item--4dd66";
export var footer__title = "index-module--footer__title--e304b";
export var footer__wrapper = "index-module--footer__wrapper--771da index-module--wrapper--04345";