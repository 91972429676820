import React from 'react';
import Layout from './src/components/Layout';
import { IntlProvider } from 'react-intl';

import messages_de from './src/translations/de.json';
import messages_en from './src/translations/en.json';

import '@fontsource/lora/400.css';
import '@fontsource/lora/400-italic.css';
import '@fontsource/lora/700.css';
import '@fontsource/lora/700-italic.css';

const messages = {
  de: messages_de,
  en: messages_en,
};

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  const currentLocale = pageContext.locale;

  return (
    <IntlProvider locale={currentLocale} messages={messages[currentLocale]}>
      <Layout
        alternatePages={pageContext.alternatePages}
        location={props.location}
      >
        {element}
      </Layout>
    </IntlProvider>
  );
};
