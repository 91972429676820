import React, { FC } from 'react';

import Link from '@blocks/Link';
import useLocalStorage from '@hooks/useLocalStorage';

import * as css from './index.module.css';

export interface LanguageSwitcherProps {
  alternatePages: {
    value: string;
    name: string;
  }[];
}

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ alternatePages }) => {
  const [language, setLanguage] = useLocalStorage('language', '');

  return (
    <ul className={css.languageSwitcher}>
      {alternatePages?.map((language) => (
        <li key={language.value} className={css.languageSwitcher__item}>
          <Link
            linkClassName={css.languageSwitcher__link}
            to={language.value}
            partiallyActive={false}
            activeClassName={css.languageSwitcher__linkActive}
            onClick={() => setLanguage(language.name)}
          >
            {language.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSwitcher;
