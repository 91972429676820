import classNames from 'classnames';
import React, { FC } from 'react';

import Link from '@blocks/Link';
import { defaultLocale } from '@config/i18n';

import Navigation, { NavigationProps } from '../Navigation';
import * as css from './index.module.css';
import Logo from './logo.inline.svg';
import Phone from './phone.inline.svg';
import { useIntl } from 'react-intl';

export interface HeaderProps {
  NavigationProps?: NavigationProps;
  isSticky?: boolean;
  navigationItems: any;
}

const Header: FC<HeaderProps> = ({ navigationItems, isSticky }) => {
  const { locale } = useIntl();

  return (
    <header
      className={classNames(css.header, { [css.headerSticky]: isSticky })}
    >
      <Link
        linkClassName={css.header__phoneWrapper}
        to="tel:0041814202121"
        target="_self"
        aria-label="Anrufen"
      >
        <Phone aria-hidden="true" title="Phone" width="20px" height="20px" />
      </Link>

      <div className={css.header__navigation}>
        <Navigation navigationItems={navigationItems} />
      </div>

      <Link
        to={`/${locale === defaultLocale ? '' : `${locale}/`}`}
        linkClassName={css.header__logo}
        aria-label="Kölliker Immobilien Homepage"
      >
        <Logo
          aria-hidden="true"
          className={css.header__logoImage}
          title="Home"
        />
      </Link>
    </header>
  );
};

export default Header;
