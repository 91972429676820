import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { helmetJsonLdProp } from 'react-schemaorg';
import { RealEstateAgent } from 'schema-dts';

import Link from '@blocks/Link';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { AlternatePages } from '@components/SEO';

import Facebook from './facebook.inline.svg';
import * as css from './index.module.css';
import Instagram from './instagram.inline.svg';
import LinkedIn from './linkedIn.inline.svg';
import Logo from './logo.inline.svg';
import { Helmet } from 'react-helmet';

export interface NavigationItem {
  id: string;
  children: ReactNode;
  to: string;
}

export interface FooterProps {
  children?: ReactNode;
  navigationItems?: NavigationItem[];
  alternatePages: AlternatePages[];
}

const Footer: FC<FooterProps> = ({
  children,
  navigationItems = [],
  alternatePages,
}) => {
  const { formatMessage } = useIntl();
  const email = 'info@koelliker-immobilien.ch';

  return (
    <footer className={css.footer}>
      <Helmet
        script={[
          helmetJsonLdProp<RealEstateAgent>({
            '@context': 'https://schema.org',
            '@type': 'RealEstateAgent',
            name: 'Kölliker Immobilien GmbH',
            image: 'https://www.koelliker-immobilien.ch/open_graph_logo.png',
            founder: 'Nathan Kölliker',
            telephone: '+41 81 420 21 21',
            url: 'https://www.koelliker-immobilien.ch/',
            email,
            sameAs: [
              'https://www.linkedin.com/company/k%C3%B6lliker-immobilien/',
              'https://www.instagram.com/koelliker_immobilien/',
              'https://www.facebook.com/K%C3%B6lliker-Immobilien-GmbH-110062610458043/',
            ],
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Promenade 120',
              postalCode: '7260',
              addressLocality: 'Davos Dorf',
            },
          }),
        ]}
      />

      <div className={css.footer__wrapper}>
        <div className={css.footer__information}>
          <div className={css.footer__address}>
            <div className={css.footer__title}>
              {formatMessage({
                id: `general.contact`,
              })}
            </div>
            <span>Kölliker Immobilien GmbH</span>
            <br />
            <span>Nathan Kölliker</span>
            <br />
            <a
              className={css.footer__link}
              href="https://goo.gl/maps/Pe8DNJX6ouiN7a4s5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Promenade 120</span>
              <br />
              <span>7260</span> <span>Davos Dorf</span>
            </a>
            <br />
            <br />
            <b>
              {formatMessage({
                id: `general.hours`,
              })}
            </b>
            <br />
            Mo - Fr 8 - 12 / 14 - 17 Uhr
            <br />
            <br />
            <a className={css.footer__link} href={`mailto:${email}`}>
              {email}
            </a>
            <br />
            <a href="tel:0041814202121" className={css.footer__link}>
              <b>+41 81 420 21 21</b>
            </a>
          </div>
          <nav className={css.footer__nav}>
            <div className={css.footer__title}>
              {formatMessage({
                id: `general.legalNotice`,
              })}
            </div>
            <ul className={css.footer__navList}>
              {navigationItems.map((item) => (
                <li key={item.id} className={css.footer__navListItem}>
                  <Link linkClassName={css.footer__link} to={item.to}>
                    {item.children}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className={css.footer__social}>
            <div className={css.footer__title}>
              {formatMessage({
                id: `general.follow`,
              })}
            </div>
            <Link
              to="https://www.linkedin.com/company/k%C3%B6lliker-immobilien/"
              linkClassName={css.footer__socialItem}
              aria-label="LinkedIn"
              target="_blank"
            >
              <LinkedIn
                aria-hidden="true"
                className={css.footer__socialImage}
                title="LinkedIn"
              />
            </Link>
            <Link
              to="https://www.instagram.com/koelliker_immobilien/"
              linkClassName={css.footer__socialItem}
              aria-label="Instagram"
              target="_blank"
            >
              <Instagram
                aria-hidden="true"
                className={css.footer__socialImage}
                title="Instagram"
              />
            </Link>
            <Link
              to="https://www.facebook.com/K%C3%B6lliker-Immobilien-GmbH-110062610458043/"
              linkClassName={css.footer__socialItem}
              aria-label="Facebook"
              target="_blank"
            >
              <Facebook
                aria-hidden="true"
                className={css.footer__socialImage}
                title="Facebook"
              />
            </Link>
          </div>
        </div>
        <div className={css.footer__additionalInformation}>
          <div>
            <Logo className={css.footer__logo} title="Logo" />
          </div>
          <div>
            <LanguageSwitcher alternatePages={alternatePages} />
          </div>
        </div>
        <div className={css.footer__copyright}>
          © {new Date().getFullYear()} Kölliker Immobilien
        </div>
      </div>

      {children}
    </footer>
  );
};

export default Footer;
