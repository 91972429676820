import { Link as GatsbyLink } from 'gatsby';
import React, { FC, MouseEvent, ReactNode } from 'react';

import Left from './left.inline.svg';
import Right from './right.inline.svg';

export interface LinkProps {
  children: ReactNode;
  to: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  linkClassName?: string;
  target?: string;
  itemProp?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  icon?: 'left' | 'right';
  iconPosition?: 'left' | 'right';
  state?: any;
}

const Link: FC<LinkProps> = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  linkClassName,
  target,
  icon,
  iconPosition = 'left',
  ...props
}) => {
  const internal = /^\/(?!\/)/.test(to);

  const renderIcon = (param: string) => {
    switch (param) {
      case 'left':
        return <Left aria-hidden="true" height="12px" />;
      case 'right':
        return <Right aria-hidden="true" height="12px" />;
      default:
        return null;
    }
  };

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={linkClassName}
        {...props}
      >
        {icon && iconPosition === 'left' && renderIcon(icon)}
        {children}
        {icon && iconPosition === 'right' && renderIcon(icon)}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={to}
      className={linkClassName}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      target={target ? target : '_blank'}
      {...props}
    >
      {icon && iconPosition === 'left' && renderIcon(icon)}
      {children}
      {icon && iconPosition === 'right' && renderIcon(icon)}
    </a>
  );
};

export default Link;
